import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/DonationForm/DonationForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/ClientLayout/ClientLayout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/DonationFormConfigProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/IFrameResizer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/PlatformLayout/CookieBanner/ClientCookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/RescalingViewportHack.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/css/application.css");
;
import(/* webpackMode: "eager" */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/IFrameLayout/IFrameLayout.module.css");
;
import(/* webpackMode: "eager" */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/PlatformLayout/PlatformLayout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/shared/components/AnalyticsProvider/ClientAnalyticsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/shared/components/Messages/Messages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/shared/components/Messages/MessagesProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/shared/components/Messages/useMessageContext.ts");
;
import(/* webpackMode: "eager" */ "/app/frontend/apps/org/src/shared/helpers/utils/scrollUtils.ts");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/gemeinsam_helfen_de.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/layout-logo-compact.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/layout-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/nussbaum.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/orga-logo-white.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/orga-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/wirwunder_logo.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/wirwunder_sparkasse.svg");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/node_modules/.pnpm/@apollo+client-react-streaming@0.11.2_@apollo+client@3.11.1_@types+react@18.3.3_graphql-ws@5._qowwct6j5pnu5vyoyuyx6o2qua/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.17.2_next@14.2.5_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.4_v5lsrond7zrlyug3sqn6q22cdq/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._b2gqpvk3qs2sjeu64r6qtcxgmq/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._b2gqpvk3qs2sjeu64r6qtcxgmq/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._b2gqpvk3qs2sjeu64r6qtcxgmq/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/ClientLayout/fonts/nussbaum.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-client-nussbaum\",\"display\":\"swap\",\"src\":[{\"path\":\"../../../../../../../../../../../../../node_modules/@betterplace/assets/fonts/clients/nussbaum/4.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../../../../../../../../../../../node_modules/@betterplace/assets/fonts/clients/nussbaum/3.woff2\",\"weight\":\"900\",\"style\":\"normal\"}]}],\"variableName\":\"nussbaum\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._b2gqpvk3qs2sjeu64r6qtcxgmq/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/ClientLayout/fonts/stadtwerkeHanau.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-client-stadtwerke-hanau\",\"display\":\"swap\",\"src\":[{\"path\":\"../../../../../../../../../../../../../node_modules/@betterplace/assets/fonts/clients/stadtwerke-hanau/ff-clan-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../../../../../../../../../../../node_modules/@betterplace/assets/fonts/clients/stadtwerke-hanau/ff-clan-bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"stadtwerkeHanau\"}");
